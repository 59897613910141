<template>
  <n-space vertical>
    <n-card>
      <n-space vertical>
        <n-avatar :size="220" round :src="user.avatar" />
      </n-space>
      <p>
        <n-gradient-text
          class="balance"
          :gradient="{
            deg: 120,
            from: 'rgb(255, 244, 45)',
            to: 'rgb(120, 120, 30)'
          }"
        >
        {{ user.balance }}
        </n-gradient-text>
      pts.</p>
    </n-card>
  </n-space>
</template>

<script>
  import { defineComponent } from 'vue'


  export default defineComponent({
    props: {
      user: Object,
    },
    components: {
    }
  })
</script>

<style scoped>
.n-card {
  text-align: center;
}
.balance {
  margin: 0;
  font-size: 300%;
  font-weight: bold;
}
</style>