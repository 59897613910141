import UserPage from './components/UserPage.vue'
import RewardPage from './components/RewardPage.vue'
import ConsumePage from './components/ConsumePage.vue'
import HelloWorld from './components/HelloWorld.vue'
import { createRouter, createWebHashHistory } from 'vue-router'


const routes = [
  { path: '/', component: UserPage },
  { path: '/reward', component: RewardPage },
  { path: '/consume', component: ConsumePage },
  { path: '/hello', component: HelloWorld },
]

export default createRouter({
  history: createWebHashHistory(),
  routes,
})