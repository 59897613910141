<template>
  <n-layout>
    <n-space vertical>
      <n-card>
        <n-form>
          <n-form-item path="amount" label="积分">
            <n-input-number
              v-model:value="reward.amount"
              @keydown.enter.prevent
            />
          </n-form-item>

          <n-form-item path="description" label="奖励原因">
            <n-space vertical>
              <n-input
                v-model:value="reward.description"
                @keydown.enter.prevent
              />
              <n-space>
                <n-tag v-for="d, idx in recent_description"
                  :key="idx"
                  @click="setRecent(d)"
                > {{ d }} </n-tag>
              </n-space>
            </n-space>
          </n-form-item>

          <n-form-item path="time" label="时间">
            <n-date-picker
              v-model:value="datetime"
              type="datetime"
              clearable
            />
          </n-form-item>

          <n-form-item path="buttons">
              <n-space>
                <n-button type="primary" @click="addReward()">奖励</n-button>
                <n-button @click="$router.push('/')">返回</n-button>
              </n-space>
          </n-form-item>
        </n-form>
      </n-card>
    </n-space>
  </n-layout>
</template>

<script>
import API from '../api'
import { ref } from 'vue'

export default {
  data() {
    return {
      reward: {
        amount: 100,
        description: '',
      },
      recent_description: [],
    };
  },
  setup () {
    return {
      datetime: ref(null)
    }
  },
  mounted() {
    API.get_recent_reward_description().then(data => {
      this.recent_description = data
    })
  },
  methods: {
    setRecent(v) {
      this.reward.description = v
    },
    addReward() {
      this.reward.created_at = this.datetime && new Date(this.datetime + 3600 * 8 * 1000).toJSON()  // XXX dirty
      console.log(this.reward)
      API.manual_reward(this.reward).then(data => console.log(data)).then(() => {
        this.$router.push({'path': '/'})
      })
    }
  },
};
</script>