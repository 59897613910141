const baseURL = process.env.VUE_APP_BASE_URL;

function get(url) {
  return fetch(`${baseURL}${url}`).then(resp => resp.json())
}

function post(url, data) {
  return fetch(`${baseURL}${url}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then(resp => resp.json())
}

export default {
  get_user_info() {
    return get('/api/get_user_info')
  },

  reward_list() {
    return get('/api/reward_list')
  },

  transaction_list() {
    return get('/api/transaction_list')
  },

  get_recent_reward_description() {
    return get('/api/get_recent_reward_description')
  },

  manual_reward(reward) {
    return post('/api/manual_reward', reward)
  },
  
  consume(consume) {
    return post('/api/consume', consume)
  },
}