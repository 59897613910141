<template>
  <router-link v-if="isAdmin" to="/reward" class="reward-link">发放奖励</router-link>
  <router-link v-if="isAdmin" to="/consume" class="consume-link">消费</router-link>
  <n-layout>
    <n-space vertical>
      <user-info :user="user" />
      <reward-history :rewardList="rewardList" />
    </n-space>
  </n-layout>
</template>

<script>
import UserInfo from "./UserInfo.vue";
import RewardHistory from "./RewardHistory.vue";
import API from "../api";

export default {
  name: "App",
  data() {
    return {
      user: {
        name: "",
        balance: 0,
      },
      rewardList: [],
      isAdmin: false,
    };
  },
  mounted() {
    console.log(this.$route)
    if(this.$route.query.isAdmin === 'true') {
      localStorage['isAdmin'] = 1
    } else if (this.$route.query.isAdmin === 'false') {
      delete localStorage['isAdmin']
    }

    this.isAdmin = localStorage['isAdmin']

    API.get_user_info().then((data) => {
      this.user = data;
      document.title = `${this.user.name} 的积分: ${this.user.balance} pts.`
    });

    API.transaction_list().then((data) => {
      this.rewardList = data;
    });
  },
  components: {
    UserInfo,
    RewardHistory,
  },
};
</script>

<style scoped>
.reward-link {
  position: fixed;
  left: 0;
  top: 0;
  display: block;
  z-index: 1000;
}
.consume-link {
  position: fixed;
  right: 0;
  top: 0;
  display: block;
  z-index: 1000;
}
</style>