<template>
  <n-layout>
    <n-space vertical>
      <n-card>
        <n-form>
          <n-form-item path="amount" label="消费积分">
            <n-input-number
              v-model:value="consume.amount"
              @keydown.enter.prevent
            />
          </n-form-item>

          <n-form-item path="description" label="消费目的">
            <n-space vertical>
              <n-input
                v-model:value="consume.description"
                @keydown.enter.prevent
              />
            </n-space>
          </n-form-item>

          <n-form-item path="time" label="时间">
            <n-date-picker
              v-model:value="datetime"
              type="datetime"
              clearable
            />
          </n-form-item>

          <n-form-item path="buttons">
              <n-space>
                <n-button type="primary" @click="addConsume()">消费</n-button>
                <n-button @click="$router.push('/')">返回</n-button>
              </n-space>
          </n-form-item>
        </n-form>
      </n-card>
    </n-space>
  </n-layout>
</template>

<script>
import API from '../api'
import { ref } from 'vue'

export default {
  data() {
    return {
      consume: {
        amount: 100,
        description: '',
      },
    };
  },
  setup () {
    return {
      datetime: ref(null)
    }
  },
  mounted() {
  },
  methods: {
    addConsume() {
      this.consume.created_at = this.datetime && new Date(this.datetime + 3600 * 8 * 1000).toJSON()  // XXX dirty
      console.log(this.consume)
      API.consume(this.consume).then(data => console.log(data)).then(() => {
        this.$router.push({'path': '/'})
      })
    }
  },
};
</script>