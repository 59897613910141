<template>
  <n-space vertical>
    <n-card v-for="(trans, idx) in rewardList" :key="idx" :class="trans.type">
      <p class="time">{{ formatTime(trans.created_at) }}</p>
      <p class="description">「{{ trans.description }}」</p>
      <p>
        <n-gradient-text
          class="pts"
          :gradient="{
            deg: 120,
            from: 'rgb(255, 244, 45)',
            to: 'rgb(120, 120, 30)'
          }"
        >
        <span v-if="trans.type == 'reward'">+</span><span v-if="trans.type == 'consume'">-</span>{{ trans.amount }}
        </n-gradient-text>
        pts.</p>
    </n-card>
  </n-space>
</template>

<script>
  import dayjs from 'dayjs'
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      rewardList: Array,
    },
    methods: {
      formatTime(v) {
        return dayjs(v).format('YYYY-MM-DD HH:mm')
      }
    },
    components: {
    }
  })
</script>

<style scoped>
.time {
  font-size: 120%;
}
.description {
  font-style: italic;
  font-weight: bold;
  margin: 1em 0;
}

.pts {
  font-size: 180%;
  font-weight: bold;
}

p {
  padding: 0 0;
  margin: 0 0;
}

.consume .pts{
  color: #7a0b0b;
}

</style>